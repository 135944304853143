const InputComponent = ({
	label,
	type,
	name,
	className,
	value,
	placeholder,
	onChange,
	readOnly,
	required,
	onKeyDown,
}) => {
	return (
		<div className={`input-container ${className}`}>
			<label htmlFor={name}>
				{label}
				{required && <span style={{ color: 'red' }}> *</span>}
			</label>
			<input
				type={type}
				name={name}
				id={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				readOnly={readOnly}
				required={required}
				onKeyDown={onKeyDown}
			/>
		</div>
	);
};

export default InputComponent;
