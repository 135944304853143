import React, { useState } from 'react';
import CustomRadio from '../customRadio';

// CSS
import '../../assets/scss/webSection.scss';

const WebSection = ({ formData, handleChange }) => {
	const [selectedRadio, setSelectedRadio] = useState(null);

	const webRadios = [
		{
			name: 'webchoice',
			value: 'Kampanjesider',
			title: 'Kampanjesider',
			price: 12000,
			subTitle: 'Enklere kommunikasjonsbehov og ofte kampanjestyrt',
			checkmarkContents: [
				'Enkle kommunikasjonsbehov der alt av innhold ligger på frontsiden',
				'Litt større prosjekt der det meste av innhold ligger på frontsiden',
				'Passer kampanjer som varer i opp til 30 dager eller mer',
			],
			caseURL: 'https://google.com',
		},
		{
			name: 'webchoice',
			value: 'Hjemmesider',
			title: 'Hjemmesider',
			price: 57900,
			subTitle: 'Ofte flere undersider og lang levetid',
			checkmarkContents: [
				'Hjemmeside med flere undersider og en del innhold',
				'Kan videreutvikles etter hvert som behov endrer se',
				'Kan være avansert med ulike koblinger og mer avanserte skjema',
			],
			caseURL: 'https://studio.adressa.no',
		},
	];

	const campAddonContent = [
		{
			name: 'Basic',
			content:
				'Basic - Passer for enkle kommunikasjonsbehov der alt av innhold ligger på frontsiden. Her kan både bilder og/eller video benyttes. Alt av innhold må være tilgjengelig før oppstart. Få korrektur-runder. Redigering gjøres av oss. Kampanjesiden varer i 30 dager.',
		},
		{
			name: 'Standard',
			content:
				'Standard - Passer for lengre kampanjeløp der alt av innhold ligger på frontsiden. Her kan både bilder og/eller video benyttes. Alt av innhold må være tilgjengelig før oppstart. Få korrekturrunder. Redigering gjøres av oss. Kampanjesiden varer i mer enn 30 dager. Pris for årlig serverleie, drift og vedlikehold kommer i tillegg.',
		},
		{
			name: 'Premium',
			content:
				'Premium - Passer for større prosjekter der alt av innhold ligger på frontsiden. Her kan både bilder og/eller video benyttes. Alt av innhold må være tilgjengelig før oppstart. Rom for flere korrektur-runder. Bør ev. oppdateres med jevne mellomrom. Redigering kan ev. gjøres av kunde. Kampanjesiden varer i mer enn 30 dager. Pris for årlig serverleie, drift og vedlikehold kommer i tillegg.',
		},
	];

	const websiteAddonContent = [
		{
			name: 'Basic',
			content:
				'Basic - Passer for middels store hjemmesider med opp til fem undersider. Ingen avansert funksjonalitet, men standard meny og kontaktskjema, ev kun kontaktinfo. Pris for årlig serverleie, drift og vedlikehold kommer i tillegg.',
		},
		{
			name: 'Standard',
			content:
				'Standard - Passer for hjemmesider med flere ulike undersider. Ingen avansert funksjonalitet, men standard meny og kontaktskjema, ev kun kontaktinfo. Pris for årlig serverleie, drift og vedlikehold kommer i tillegg.',
		},
		{
			name: 'Premium',
			content:
				'Premium - Passer for store hjemmesider med flere undersider der det er behov for skreddersøm og ev. kobling mot systemer. Meny og skjema kan være mer tilpasset. Pris for årlig serverleie, drift og vedlikehold kommer i tillegg.',
		},
	];

	const weblanding = [
		{
			name: 'weblanding',
			content: 'Ønsker å benytte egen URL. URL finnes allerede i dag.',
			id: 'landing1',
		},
		{
			name: 'weblanding',
			content:
				'Ønsker å benytte standard URL. URL finnes ikke og må bestilles.',
			id: 'landing2',
		},
		{
			name: 'weblanding',
			content:
				'Ønsker å benytte standard URL foreslått av Adresseavisen.',
			id: 'landing3',
		},
	];

	function formatPrice(price) {
		return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	}

	return (
		<div className='webSection'>
			<div className='radio-cards'>
				<div className='radio-container'>
					{webRadios.map((radio, index) => (
						<CustomRadio
							key={index}
							isSelected={formData[radio.name] === radio.value}
							handleChange={handleChange}
							formData={formData}
							name={radio.name}
							value={radio.value}
							title={radio.title}
							price={formatPrice(radio.price)}
							subTitle={radio.subTitle}
							checkmarkContents={radio.checkmarkContents}
							caseURL={radio.caseURL}
						/>
					))}
				</div>
			</div>

			{formData.webchoice === 'Kampanjesider' && (
				<div className='campAddon'>
					<h3>Valg for Kampanjesider</h3>
					<small>Klikk på en boks for å velge</small>
					<div className='choices'>
						{campAddonContent.map((radio, index) => (
							<div className='choice'>
								<input
									key={index}
									type='radio'
									id={radio.name}
									name='campChoice'
									value={radio.content}
									onChange={(e) => handleChange(e)}
								/>
								<label
									htmlFor={radio.name}
									className={
										formData.campChoice === radio.content
											? 'selected'
											: ''
									}
								>
									{radio.content}
								</label>
							</div>
						))}
					</div>
				</div>
			)}
			{formData.webchoice === 'Hjemmesider' && (
				<div className='websiteAddon'>
					<h3>Valg for Hjemmesider</h3>
					<small>Klikk på en boks for å velge</small>
					<div className='choices'>
						{websiteAddonContent.map((radio, index) => (
							<div className='choice'>
								<input
									key={index}
									type='radio'
									id={radio.name}
									name='websiteChoice'
									value={radio.content}
									onChange={(e) => handleChange(e)}
								/>
								<label
									htmlFor={radio.name}
									className={
										formData.websiteChoice === radio.content
											? 'selected'
											: ''
									}
								>
									{radio.content}
								</label>
							</div>
						))}
					</div>
				</div>
			)}

			<div className='weblandings'>
				<div className='weblandingstitles'>
					<h3>Landigsside / Ønsket domene</h3>
					<p className='weblandinginfo'>
						- Dersom det er ønskelig med et eget domene for
						landingssiden, må vi vite om domenet eksisterer i dag
						eller om vi skal bestille dette. <br />
						Om ikke annet er avtalt benytter vi standarden vi bruker
						for domene for slike landingssider.
					</p>
				</div>

				<div className='weblandingcontent'>
					{weblanding.map((radio, index) => (
						<div className='landinginput'>
							<input
								type='radio'
								name={radio.name}
								id={radio.id}
								value={radio.content}
								onChange={(e) => handleChange(e)}
								key={index}
							/>
							<label htmlFor={radio.id}>{radio.content}</label>
						</div>
					))}
				</div>

				<div className='weblandingurl'>
					<label htmlFor='weblandingurl'>Landingsside</label>
					<input
						type='url'
						name='weblandingurl'
						id='weblandingurl'
						onChange={handleChange}
						placeholder='https://studio.adressa.no'
					/>
					<small>
						Skriv inn landingsside for Web om du har dette ( Må
						inneholde https:// )
					</small>
				</div>
			</div>
		</div>
	);
};

export default WebSection;
