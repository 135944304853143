const TextareaComponent = ({ label, cols, rows, name, className, value, onChange, required }) => {
    return (
        <div className={  `input-container ${className}` }>
            <label htmlFor={name}>
                {label}
                {required && <span style={{ color: 'red' }}> *</span>}
            </label>
            <textarea
                cols={cols}
                rows={rows}
                name={name}
                id={name}
                value={value}
                onChange={onChange}
                required={required}
            />
        </div>
    );
};

export default TextareaComponent;