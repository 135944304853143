import CheckboxComponent from "./CheckboxComponent";

const PlatformSection = ({ platform, choices, formData, handleChange, handlePriceChange }) => {
    return (
      <div className={`${platform}SelectedSection`}>
        <h2>{platform}</h2>
        <div className={`${platform}Choices`}>
          {choices.map((choice, index) => {
            const extraProps = {};
            if (choice.price) {
              extraProps['data-price'] = choice.price;
            }
            return (
              <CheckboxComponent
                key={index}
                label={choice.label}
                type="checkbox"
                name={`${platform}${choice.name}`}
                checked={formData[`${platform}${choice.name}`]}
                onChange={(e) => {
                  handleChange(e);
                  if(choice.price) {
                    handlePriceChange(e);
                  }
                }}
                className={`${platform}${choice.name}`}
                value={true}
                {...extraProps}
              />
            );
          })}
        </div>
      </div>
    );
};

export default PlatformSection;