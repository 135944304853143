import React from 'react';
import '../assets/scss/orderRoutines.scss';

const OrderRoutines = () => {
	return (
		<section className='order-routines'>
			<div className='order-routines__header'>
				<h1>Bestillingsrutiner</h1>
			</div>

			<div className='order-routines__info'>
				<p>Bestillinger gjøres via bestillingsskjema</p>
				<p>
					Forespørsler på pris og oppdrag sendes til{' '}
					<a href='mailto:studio@adresseavisen.no'>
						studio@adresseavisen.no
					</a>
				</p>
			</div>

			<div className='order-routines__content'>
				<div className='register-order'>
					<div className='register-order__title'>
						<h2>Registrer ordre</h2>
					</div>
					<div className='register-order__content'>
						<p className='heading'>
							Ordre registreres i Saleslink med riktig
							produksjonkode
						</p>
						<ul>
							{/* <li>ADR Marketing Studio</li>
							<li>ADR Brand Studio</li>
							<li>ADR AnnSent</li> */}
							<li>ADR Produksjon</li>
						</ul>

						<small>
							<b>NB!</b> Ferdigmateriell skal kun brukes når
							annonsen kommer ferdig levert fra kunde.
							Ordrelinjene fra Rose kommer automatisk som "ADR
							Ferdigmateriell" i SalesLink og må derfor endres i
							SalesLink.
						</small>
					</div>
				</div>
				<div className='treat-order'>
					<div className='treat-order__title'>
						<h2>Behandle ordre</h2>
					</div>
					<div className='treat-order__content'>
						<ul>
							<li>Materiell/Underlag legges inn i IX</li>
							<li>Fyll ut bestillingsskjema og send inn.</li>
							<li>Endre status til "Kan produseres" i IX.</li>
						</ul>
					</div>
				</div>
				<div className='follow-order'>
					<div className='follow-order__title'>
						<h2>Følg opp ordre</h2>
					</div>
					<div className='follow-order__content'>
						<ul>
							<li>
								Korrektur sendes fra{' '}
								<a href='mailto:studio@adresseavisen.no'>
									studio@adresseavisen.no
								</a>{' '}
								(Så fremt ikke annet er avtalt)
							</li>
							<li>
								Konsulenten skal alltid stå på kopi hvis{' '}
								<b>Studio</b> sender direkte til kunden.
							</li>
							<li>
								Når korrektur er godkjent sender produsenten inn
								materiellet.
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OrderRoutines;
