const CheckboxComponent = ({
	label,
	type,
	name,
	value,
	className,
	checked,
	onChange,
	...rest
}) => {
	const cardClass = checked ? 'checkbox-card checked' : 'checkbox-card';

	return (
		<div className={`checkbox-container ${className}`}>
			<input
				type={type}
				name={name}
				id={value}
				checked={checked}
				onChange={onChange}
				value={value}
				className='checkbox-card-input'
				{...rest}
			/>
			<label
				className={`checkbox-card-label ${cardClass}`}
				htmlFor={value}
			>
				{label}
			</label>
		</div>
	);
};

export default CheckboxComponent;
