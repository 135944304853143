import React, { useEffect, useState } from 'react';

// CSS
import '../assets/scss/ListOrder.scss';

const ChangeOrder = () => {
	const [stillingTasks, setStillingTasks] = useState([]);
	const [generellTasks, setGenerellTasks] = useState([]);
	const [isLoadingStilling, setIsLoadingStilling] = useState(false);
	const [isLoadingGenerell, setIsLoadingGenerell] = useState(false);

	useEffect(() => {
		setIsLoadingStilling(true); // Start loading for Stilling
		setIsLoadingGenerell(true); // Start loading for Generell

		const fetchTasks = async (endpoint, setTasks, setLoading) => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/api/asana${endpoint}`
				);
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const tasks = await response.json();
				// console.log("Tasks from API:", tasks); // Debugging log
				const activeTasks = tasks.filter((task) => !task.completed);
				setTasks(activeTasks);
			} catch (error) {
				console.error(`Error fetching ${endpoint} tasks:`, error);
			} finally {
				setLoading(false); // Stop loading after fetching tasks
			}
		};

		fetchTasks('/tasks/stilling', setStillingTasks, setIsLoadingStilling);
		fetchTasks('/tasks/generell', setGenerellTasks, setIsLoadingGenerell);
	}, []);

	const renderTaskList = (tasks, header, isLoading) => (
		<div>
			<h2>{header}</h2>
			{isLoading ? (
				<div className='loading-indicator'>
					<div className='spinner'></div>
					<p>Laster inn {header}...</p>
				</div>
			) : (
				<ul>
					{tasks.map((task) => (
						<li key={task.gid}>
							{task.name}
							<button
								onClick={() =>
									console.log(
										`Change order for task ${task.gid}`
									)
								}
							>
								Change Order
							</button>
						</li>
					))}
				</ul>
			)}
		</div>
	);

	return (
		<div className='list-orders'>
			<h1>Liste over alle ordre</h1>
			<div className='list-items'>
				{renderTaskList(stillingTasks, 'Stilling', isLoadingStilling)}
				{renderTaskList(generellTasks, 'Generell', isLoadingGenerell)}
			</div>
		</div>
	);
};

export default ChangeOrder;
