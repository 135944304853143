import React from 'react';

export const UserContext = React.createContext({
    user: {
      isLoggedIn: false,
      role: null,
      first_name: null,
      last_name: null,
      email: null,
    },
    setUser: () => {}
});

export default UserContext;