import React from 'react';
import CheckboxComponent from '../CheckboxComponent';
import DatePicker from '../DatePicker';
import InputComponent from '../InputComponent';
import TextareaComponent from '../TextareaComponent';

// SCSS
import '../../assets/scss/graphical.scss';

const Graphical = ({
	formData,
	handleChange,
	setFormData,
	handleCheckboxChange,
	selectedSizes,
	displayFormats,
	services,
	handlePriceChange,
}) => {
	const handleGraphicalChange = (e, index = null) => {
		const { name, value, type, checked } = e.target;
		const updateValue = type === 'checkbox' ? checked : value;

		if (name === 'displayrosenummer') {
			let updatedRosenummere = [...formData.displayrosenummer];
			updatedRosenummere[index] = updateValue;
			setFormData({ ...formData, displayrosenummer: updatedRosenummere });
		} else {
			// For other inputs, use handleChange
			handleChange({
				target: {
					name,
					value: updateValue,
				},
			});
		}
	};

	const addRosenummerField = () => {
		setFormData({
			...formData,
			displayrosenummer: [...formData.displayrosenummer, ''],
		});
	};

	return (
		<div className='graphicalSection'>
			<h2>Grafisk produksjon</h2>
			<div className='graphicalChoice'>
				<CheckboxComponent
					label='Displayannonse'
					name='graphDisplay'
					onChange={handleChange}
					value='Displayannonse'
					type='checkbox'
					checked={formData.graphDisplay || false}
				/>
				<CheckboxComponent
					label='Papirannonse'
					name='graphPaper'
					onChange={handleChange}
					value='Papirannonse'
					type='checkbox'
					checked={formData.graphPaper || false}
				/>
				<CheckboxComponent
					label='Stillingskarusell'
					name='graphStillingskarusell'
					onChange={handleChange}
					value='Stillingskarusell'
					type='checkbox'
					checked={formData.graphStillingskarusell || false}
				/>
				<CheckboxComponent
					label='Annet'
					name='graphOther'
					onChange={handleChange}
					value='Annet'
					type='checkbox'
					checked={formData.graphOther || false}
				/>
			</div>

			{/* DISPLAYANNONSE VISNING */}
			{formData.graphDisplay && (
				<div className='displayContentSection'>
					<h2>Type displayannonse</h2>
					<div className='whatDisplay'>
						<CheckboxComponent
							label='Display JPG'
							type='checkbox'
							value='Display JPG'
							onChange={handleChange}
							name='displayjpg'
							checked={formData.displayjpg || false}
						/>
						<CheckboxComponent
							label='Display Adssets ACM'
							type='checkbox'
							value='Display Adssets ACM'
							onChange={handleChange}
							name='displayadssets'
							checked={formData.displayadssets || false}
						/>
						<CheckboxComponent
							label='Bannerflow'
							type='checkbox'
							value='Bannerflow'
							onChange={handleChange}
							name='bannerflow'
							checked={formData.bannerflow || false}
						/>
					</div>
					{formData.bannerflow && (
						<div className='bannerflowSelected'>
							<CheckboxComponent
								label='Video'
								type='checkbox'
								value='Bannerflow Video'
								onChange={handleChange}
								name='bannerflowvideo'
								checked={formData.bannerflowvideo || false}
							/>
						</div>
					)}

					<div className='displayFormats'>
						<div className='title'>
							<h2>Format på annonsen</h2>
						</div>
						<div className='formatsContent'>
							{displayFormats.map((size) => (
								<>
									<CheckboxComponent
										label={size.label}
										type='checkbox'
										name={size.name}
										value={size.value}
										checked={formData[size.name] || false}
										onChange={handleCheckboxChange}
										className='formatCheckboxes'
									/>
								</>
							))}
						</div>
					</div>

					<div className='displayPeriods'>
						<div className='inputContent'>
							<DatePicker
								name='stilldatofra'
								label='Dato fra'
								handleChange={handleChange}
								required
							/>
						</div>
						<div className='inputContent'>
							<DatePicker
								name='stilldatotil'
								label='Dato til'
								handleChange={handleChange}
								required
							/>
						</div>
						<div className='inputContent'>
							<InputComponent
								label='Antall visninger'
								type='number'
								name='displayvisninger'
								value={formData.displayvisninger}
								onChange={handleChange}
								required
							/>
						</div>
						<div className='inputContent'>
							<InputComponent
								label='CPM'
								type='number'
								name='displaycpm'
								value={
									formData.displayvisninger
										? Math.round(
												formData.displayvisninger * 0.01
										  )
										: 0
								}
								onChange={handleChange}
								readOnly={true}
							/>
						</div>
						<div className='inputContent'>
							{Array.isArray(formData.displayrosenummer) &&
								formData.displayrosenummer.map(
									(rosenummer, index) => (
										<InputComponent
											key={index}
											label={`Rosenummer ${index + 1}`}
											type='number'
											name='displayrosenummer'
											value={rosenummer}
											onChange={(e) =>
												handleGraphicalChange(e, index)
											}
											required
											className='displayrosenummer'
										/>
									)
								)}
							<button
								type='button'
								onClick={addRosenummerField}
								className='addRosenummerBtn'
							>
								Legg til et nytt Rosenummer
							</button>
						</div>
						{/* <div className="inputContent">
                            <InputComponent 
                                label="ATE"
                                type="number"
                                name="displayate"
                                value={formData.displayate}
                                onChange={handleChange}
                            />
                        </div> */}
					</div>
				</div>
			)}

			{/* PAPIRANNONSE VISNING */}
			{formData.graphPaper && (
				<div className='paperContentSection'>
					<h2>Papirannonse</h2>
					<TextareaComponent
						className='papirannonseTextarea'
						label='Beskriv bestillingen'
						onChange={handleChange}
						name='graphpapertext'
						value={formData.graphpapertext}
						cols='40'
						rows='5'
					/>
				</div>
			)}

			{/* STILLINGSKARUSELL VISNING */}
			{formData.graphStillingskarusell && (
				<div className='stillingskarusellContentSection'>
					<h2>Stillingskarusell</h2>
					<div className='stillingskarusellContent'>
						<DatePicker
							label='Dato fra'
							name='stilldatefrom'
							handleChange={handleChange}
							value={formData.stilldatefrom}
						/>
						<DatePicker
							label='Dato til'
							name='stilldateto'
							handleChange={handleChange}
							value={formData.stilldateto}
						/>
					</div>
				</div>
			)}

			{/* ANNET VISNING */}
			{formData.graphOther && (
				<div className='annetContentSection'>
					<h2>Beskriv din bestilling</h2>
					<div className='annetContent'>
						<TextareaComponent
							cols='40'
							rows='5'
							name='graphothertext'
							value={formData.graphothertext}
							onChange={handleChange}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default Graphical;
