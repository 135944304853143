import React from 'react';

import Checkmark from '../assets/images/checkmark.svg';
import '../assets/scss/customRadio.scss';

const CustomRadio = ({
	name,
	value,
	title,
	price,
	subtitle,
	checkmarkContents,
	caseURL,
	caseImage,
	isSelected,
	formData,
	handleChange,
}) => {
	const handleClick = (e) => {
		if (isSelected) {
			e.preventDefault();
			handleChange({ target: { name, value: null } }); // Unselect the radio button
		}
	};

	const chosenClassName =
		formData[name] === value ? 'chosen selected' : 'chosen';

	return (
		<div className='radio-box'>
			<input
				type='radio'
				name={name}
				id={value}
				className='hidden-radio'
				value={value}
				checked={formData[name] === value}
				onChange={handleChange}
				onClick={handleClick}
			/>
			<label htmlFor={value} className='custom-radio'>
				<div className='content-box'>
					<div className='title'>
						<h4>{title}</h4>
						<div className='price'>
							<small className='price_from'>Pris fra:</small>
							<p>
								{price},- <br />
								<small>Eks. mva</small>
							</p>
						</div>
					</div>
					<div className='sub-title'>
						<p>{subtitle}</p>
					</div>
					{/* End Title block */}

					{/* Order checkmarks */}
					<div className='checkmarks'>
						{checkmarkContents.map((content, index) => (
							<div className='checks' key={index}>
								<img src={Checkmark} alt='ingenting' />
								<p>{content}</p>
							</div>
						))}
					</div>
					{/* End Checkmarks block */}

					{/* Image display */}
					{caseImage && (
						<>
							<a
								className='img_url'
								href={caseURL}
								target='_blank'
								rel='noreferrer'
							>
								<img src={caseImage} alt='' />
							</a>
							<small className='img_small'>
								Trykk på bildet for å se eksempel av annonse
							</small>
						</>
					)}
					{/* End image display */}
				</div>
				<div className={chosenClassName}>
					<p>
						{formData[name] === value
							? 'Denne er valgt'
							: 'Bestill'}
					</p>
				</div>
			</label>
		</div>
	);
};

export default CustomRadio;
