import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import '../../assets/scss/resetPassword.scss';

const ResetPassword = () => {
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState('');

	const { token: resetToken } = useParams();

	const [email, setEmail] = useState('');

	console.log('Extracted token:', resetToken);

	const handleResetPassword = () => {
		if (password !== confirmPassword) {
			setMessage('Passwords do not match');
			return;
		}

		if (!email) {
			setMessage('Vennligst skriv inn Epost adressen din');
			return;
		}
		if (password !== confirmPassword) {
			setMessage('Passordene er ikke like. Prøv igjen!');
			return;
		}

		fetch(`${process.env.REACT_APP_API_URL}/api/users/reset-password`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email, newPassword: password, resetToken }),
		})
			.then((res) => res.json())
			.then((data) => {
				setMessage(data.message);
			})
			.catch((error) => {
				console.error('Error during password reset:', error);
			});
	};

	return (
		<div className='resetpsw'>
			<h1>Tilbakestill passord</h1>
			<p>Skriv din Epost og ditt nye passord 2 ganger.</p>
			<input
				type='email'
				placeholder='Din Epost'
				value={email}
				onChange={(e) => setEmail(e.target.value)}
			/>
			<input
				type='password'
				placeholder='Nytt passord'
				value={password}
				onChange={(e) => setPassword(e.target.value)}
			/>
			<input
				type='password'
				placeholder='Bekreft nytt passord'
				value={confirmPassword}
				onChange={(e) => setConfirmPassword(e.target.value)}
			/>
			<button onClick={handleResetPassword}>Tilbakestill passord</button>
			{message && <p>{message}</p>}
		</div>
	);
};

export default ResetPassword;
