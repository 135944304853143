import React from 'react';
import CheckboxComponent from '../CheckboxComponent';
import InputComponent from '../InputComponent';

import '../../assets/scss/whatorder.scss';

const WhatOrder = ({ formData, handleChange }) => {
	const stillNeeded = formData.typeOfOrder === 'Stilling';

	return (
		<section className='whatOrderSection'>
			<h2>Hva slags bestilling skal du foreta deg?</h2>
			<div className='row'>
				<CheckboxComponent
					label='Generell'
					type='radio'
					name='typeOfOrder'
					value='Generell'
					onChange={handleChange}
					className='generellOrdre'
					checked={formData.typeOfOrder === 'Generell'}
				/>

				<CheckboxComponent
					label='Stilling'
					type='radio'
					name='typeOfOrder'
					value='Stilling'
					onChange={handleChange}
					className='stillingOrdre'
					checked={formData.typeOfOrder === 'Stilling'}
				/>
			</div>

			{formData.typeOfOrder === 'Stilling' && (
				<div className='stillSelectedShow'>
					<h2>Stillingstittel</h2>
					<div className='stillContent'>
						<>
							<InputComponent
								label='Stillingstittel'
								type='text'
								name='stilltitle'
								value={formData.stilltitle}
								onChange={handleChange}
								required={stillNeeded}
							/>
						</>
						<>
							<InputComponent
								label='Arbeidsgivers navn på den som søker'
								type='text'
								name='stillcampname'
								value={formData.stillcampname}
								onChange={handleChange}
							/>
						</>
					</div>
				</div>
			)}
		</section>
	);
};

export default WhatOrder;
