import React, { useEffect } from 'react';
import CheckboxComponent from '../CheckboxComponent';
import InputComponent from '../InputComponent';

import PlatformSection from '../PlatformSection';
import PeriodsSection from '../periodsSection';

import '../../assets/scss/external.scss';

const External = ({
	formData,
	handleChange,
	sections,
	setFormData,
	addPeriod,
	removeLastPeriod,
	services,
	handlePriceChange,
}) => {
	const facebookImgVid = services['Facebook: Bilde- og videoannonse'];
	const facebookKar = services['Facebook karusell'];
	const snapGeo = services['Snapchat: Geo-filter'];
	const linkedImgVid =
		services['LinkedIn: Bilde- eller videoannonse, karusell'];
	const googYoutube = services['YouTube'];
	const rapCamp = services['Rapport-2'];
	const rapCampUten = services['Rapport'];

	const facebookChoices = [
		{ label: 'Bildeannonse', name: 'Bildeannonse', price: facebookImgVid },
		{ label: 'Videoannonse', name: 'Videoannonse', price: facebookImgVid },
		{ label: 'Karusell', name: 'Karusell', price: facebookKar },
		{ label: 'Lysbildeframvisning', name: 'Lysbildeframvisning' },
		{ label: 'Samling', name: 'Samling' },
		{ label: 'Grafisk materiale', name: 'Grafisk materiale' },
	];

	const linkedinChoices = [
		{ label: 'Bildeannonse', name: 'Bildeannonse', price: linkedImgVid },
		{ label: 'Videoannonse', name: 'Videoannonse', price: linkedImgVid },
		{ label: 'Karusell', name: 'Karusell', price: linkedImgVid },
		{ label: 'In-mail', name: 'In-mail' },
		{ label: 'Grafisk materiale', name: 'Grafisk materiale' },
	];

	const snapchatChoices = [
		{ label: 'Bildeannonse', name: 'Bildeannonse' },
		{ label: 'Videoannonse', name: 'Videoannonse' },
		{ label: 'Collection ad', name: 'Collection ad' },
		{ label: 'Geo-filter', name: 'Geo-filter', price: snapGeo },
		{ label: 'Grafisk materiale', name: 'Grafisk materiale' },
	];

	const googleChoices = [
		{ label: 'Display flatfil', name: 'Display flatfil' },
		{ label: 'Display video', name: 'Display video' },
		{ label: 'Youtube', name: 'Youtube', price: googYoutube },
		{ label: 'Søk', name: 'Søk' },
		{ label: 'Grafisk materiale', name: 'Grafisk materiale' },
	];

	useEffect(() => {}, [sections, setFormData]);

	const handleExternalChange = (e) => {
		const { name, value, type, checked } = e.target;
		const updateValue = type === 'checkbox' ? checked : value;
		handleChange({
			target: {
				name,
				value: updateValue,
			},
		});
	};

	return (
		<div className='external-channels'>
			<div className='external-top-inputs'>
				<InputComponent
					label='Målsetning'
					type='text'
					name='externalMalsetning'
					value={formData.externalMalsetning}
					onChange={handleChange}
					className='externalMalsetning'
					required
					placeholder='F.eks Klikk til landingsside'
				/>

				<InputComponent
					label='Målretting'
					type='text'
					name='externalMalretting'
					value={formData.externalMalretting}
					onChange={handleChange}
					className='externalMalretting'
					required
					placeholder='F.eks Voksne 40-60 år'
				/>

				<InputComponent
					label='Geo'
					type='text'
					name='externalGeo'
					value={formData.externalGeo}
					onChange={handleChange}
					className='externalGeo'
					required
					placeholder='F.eks TrHeim + 40km'
				/>
			</div>

			<div className='report-message'>
				<p>
					Hvis <b>KUN</b> "Rapport under er valgt trenger du ikke
					fylle ut feltene "Målsetning, Målretting og Geo"
				</p>
			</div>

			<div className='external-checkboxes'>
				<CheckboxComponent
					label='Facebook'
					type='checkbox'
					name='externalFacebook'
					checked={formData.externalFacebook}
					onChange={handleChange}
					className='externalFacebook'
					value={true}
				/>
				<CheckboxComponent
					label='LinkedIn'
					type='checkbox'
					name='externalLinkedin'
					checked={formData.externalLinkedin}
					onChange={handleChange}
					className='externalLinkedin'
					value={true}
				/>
				<CheckboxComponent
					label='Snapchat'
					type='checkbox'
					name='externalSnapchat'
					checked={formData.externalSnapchat}
					onChange={handleChange}
					className='externalSnapchat'
					value={true}
				/>
				<CheckboxComponent
					label='Google'
					type='checkbox'
					name='externalGoogle'
					checked={formData.externalGoogle}
					onChange={handleChange}
					className='externalGoogle'
					value={true}
				/>
				<CheckboxComponent
					label='Rapport'
					type='checkbox'
					name='externalRapport'
					checked={formData.externalRapport}
					onChange={handleChange}
					className='externalRapport'
					value={true}
				/>
			</div>

			<div className='someSection'>
				{formData.externalFacebook && (
					<>
						<PlatformSection
							platform='Facebook'
							choices={facebookChoices}
							formData={formData}
							handleChange={handleChange}
							handlePriceChange={handlePriceChange}
						/>
						<PeriodsSection
							platform='facebook'
							formData={formData}
							handleChange={handleChange}
							addPeriod={addPeriod}
							removeLastPeriod={removeLastPeriod}
						/>
					</>
				)}

				{formData.externalLinkedin && (
					<>
						<PlatformSection
							platform='Linkedin'
							choices={linkedinChoices}
							formData={formData}
							handleChange={handleChange}
							handlePriceChange={handlePriceChange}
						/>
						<PeriodsSection
							platform='linkedin'
							formData={formData}
							handleChange={handleChange}
							addPeriod={addPeriod}
							removeLastPeriod={removeLastPeriod}
						/>
					</>
				)}
				{formData.externalSnapchat && (
					<>
						<PlatformSection
							platform='Snapchat'
							choices={snapchatChoices}
							formData={formData}
							handleChange={handleChange}
							handlePriceChange={handlePriceChange}
						/>
						<PeriodsSection
							platform='snapchat'
							formData={formData}
							handleChange={handleChange}
							addPeriod={addPeriod}
							removeLastPeriod={removeLastPeriod}
						/>
					</>
				)}

				{formData.externalGoogle && (
					<>
						<PlatformSection
							platform='Google'
							choices={googleChoices}
							formData={formData}
							handleChange={handleChange}
							handlePriceChange={handlePriceChange}
						/>
						<PeriodsSection
							platform='google'
							formData={formData}
							handleChange={handleChange}
							addPeriod={addPeriod}
							removeLastPeriod={removeLastPeriod}
						/>
					</>
				)}
				{formData.externalRapport && (
					<div className='rapportSection'>
						<h2>Rapport</h2>
						<div className='rapport-content'>
							{formData.typeOfOrder === 'Stilling' && (
								<CheckboxComponent
									label='Kampanjerapport - 1600,-'
									type='checkbox'
									name='externalRapportKampanje'
									checked={formData.externalRapportKampanje}
									onChange={(e) => {
										handleChange(e);
										handlePriceChange(e);
									}}
									className='externalRapportKampanje'
									value='Kampanjerapport - 1600,-'
									data-price={rapCamp}
								/>
							)}

							{formData.typeOfOrder === 'Generell' && (
								<>
									<CheckboxComponent
										label='Kampanjerapport med innsikt - 3000,-'
										type='checkbox'
										name='externalRapportInnsikt'
										checked={
											formData.externalRapportInnsikt
										}
										onChange={(e) => {
											handleChange(e);
											handlePriceChange(e);
										}}
										className='externalRapportInnsikt'
										value='Kampanjerapport med innsikt - 3000,-'
										data-price='3000'
									/>
									<CheckboxComponent
										label='Kampanjerapport uten innsikt - 2500,-'
										type='checkbox'
										name='externalRapportUtenInnsikt'
										checked={
											formData.externalRapportUtenInnsikt
										}
										onChange={(e) => {
											handleChange(e);
											handlePriceChange(e);
										}}
										className='externalRapportUtenInnsikt'
										value='Kampanjerapport uten innsikt - 2500,-'
										data-price={rapCampUten}
									/>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default External;
