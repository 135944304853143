import React from 'react';

import CheckboxComponent from '../CheckboxComponent';
// CSS
import '../../assets/scss/rapanalytics.scss';

const Rapanalytics = ({ formData, handleChange }) => {
    return (
        <div className="rapanalyticsSection">
            <h2>Rapport og Analyse</h2>

            <div className="rapanalyticsContent">
                <div className="title">
                    <h3>Velg Pakke</h3>
                </div>

                <div className="rapanalyticsPackage">
                    <div className="package">
                        <CheckboxComponent 
                            label="Opprette GA4 konto uten events sporing"
                            type="checkbox"
                            name="analyticsga"
                            onChange={handleChange}
                            checked={formData.analyticsga || false}
                            value="Opprette GA4 konto uten events sporing"
                        />
                    </div>
                    <div className="package">
                        <CheckboxComponent 
                            label="Rapport | Dashboard - 12 måneders live dashboard med leads, trafikk- og eventsdata. Spesifikk events-tracking må avklares med Studio."
                            type="checkbox"
                            name="rapdash"
                            checked={formData.rapdash || false}
                            onChange={handleChange}
                            value="Rapport | Dashboard"
                        />
                    </div>
                </div>

                {formData.rapdash && (
                <div className="rapaddons">
                    <div className="rapdash">
                        <h3>Tillegg for Rapport | Dashboard</h3>
                        <div className="package">
                            <CheckboxComponent 
                                label="Kampanjerapport med innsikt - 3000,-"
                                type="checkbox"
                                name="rapcamptracking"
                                onChange={handleChange}
                                checked={formData.rapcamptracking || false}
                                value="Kampanjerapport med innsikt - 3000,-"
                            />
                        </div>
                        <div className="package">
                            <CheckboxComponent 
                                label="Kampanjerapport uten innsikt - 2500,-"
                                type="checkbox"
                                name="rapcampnotrack"
                                onChange={handleChange}
                                checked={formData.rapcampnotrack || false}
                                value="Kampanjerapport uten innsikt - 2500,-"
                            />
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
};


export default Rapanalytics;