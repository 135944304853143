import React, { useState } from 'react';
import '../assets/scss/checklist.scss';

const CheckList = () => {
	const [activeAccordion, setActiveAccordion] = useState(null);

	const toggleAccordion = (accordionNumber) => {
		setActiveAccordion(
			activeAccordion === accordionNumber ? null : accordionNumber
		);
	};

	return (
		<section className='checklist'>
			<div className='checklist__header'>
				<h1>Sjekkliste ved bestilling</h1>
			</div>

			<div className='checklist__content'>
				<div className='info'>
					<h3>Trykk på en fane for å velge et tema.</h3>
					<small>
						Ved spørsmål ta kontakt med{' '}
						<a href='mailto:studio@adresseavisen.no'>
							studio@adresseavisen.no
						</a>
					</small>
				</div>

				<div className='accordion_content'>
					{/* Accordion 1 */}
					<div className='accordion-item'>
						<div
							className={`accordion-title ${
								activeAccordion === 1 ? 'active' : ''
							}`}
							onClick={() => toggleAccordion(1)}
						>
							<h3>Innholdsmarkedføring</h3>
						</div>
						{activeAccordion === 1 && (
							<div className='accordion-content'>
								<p>Sjekkliste Innhold</p>

								<ul>
									<li>
										Hva er målet med sak/annonsering? Hvem
										er målgruppen?
									</li>
									<li>Hvorfor skal de ut med budskapet? </li>
									<li>
										Når ønsker kunden oppstart på
										produksjonen?{' '}
									</li>
									<li>
										Hvor skal produksjonen gjøres? Location?
										(tenkt visuelt){' '}
									</li>
									<li>
										Skal det være med video? Hvordan kan det
										løses?{' '}
									</li>
									<li>Når og hvor skal det publiseres? </li>
									<li>
										Skal det være content/content
										premium/premium Norge?
									</li>
									<li>
										Finnes det oppsalgsmuligheter? (tekst
										til nettside, video til SoMe)
									</li>
								</ul>
							</div>
						)}
					</div>

					{/* Accordion 2 */}
					<div className='accordion-item'>
						<div
							className={`accordion-title ${
								activeAccordion === 2 ? 'active' : ''
							}`}
							onClick={() => toggleAccordion(2)}
						>
							<h3>Eksterne kanaler</h3>
						</div>
						{activeAccordion === 2 && (
							<div className='accordion-content'>
								<p>Sjekkliste ved innsalg:</p>

								<ul>
									<li>
										Hva er målet med annonsering i denne
										kanalen?
									</li>
									<li>
										Hvilke formater/annonsetyper skal
										brukes? Skal kreativer oppdateres
										underveis i kampanjeperioden?
									</li>
									<li>
										Ulike kreativer til forskjellige
										målgrupper?
									</li>
									<li>
										Skal kampanjen optimaliseres i perioden
										(kampanjeperiode over 14 dager)
									</li>
									<li>
										Samtykkeskjema fra kunde ved Leads
										annonser (Facebook, LinkedIn, Snapchat)
									</li>
									<li>
										Har kunden en UTM struktur som Studio
										skal følge?
									</li>
									<li>
										Er Facebook Pixel satt opp mot
										registeringsskjema på landingsside?
									</li>
									<li>
										Skal det kjøres retargeting kampanje? Da
										er pixel nødvendig.
									</li>
								</ul>
							</div>
						)}
					</div>

					{/* Accordion 3 */}
					<div className='accordion-item'>
						<div
							className={`accordion-title ${
								activeAccordion === 3 ? 'active' : ''
							}`}
							onClick={() => toggleAccordion(3)}
						>
							<h3>Landingsside</h3>
						</div>
						{activeAccordion === 3 && (
							<div className='accordion-content'>
								<p>Sjekkliste ved innsalg:</p>

								<ul>
									<li>
										Hva er målet med landingssiden? Hva
										ønsker kunde å oppnå?
									</li>
									<li>
										Hvem skal være mottaker av
										kontaktskjema?
									</li>
									<li>
										Er nettside oppdatert med riktig innhold
										(tekst og bilde)?
									</li>
									<li>
										Er Google Analytics eller GA4 script
										aktivert?
									</li>
									<li>Er Facebook pixel aktivert?</li>
									<li>Er div. Retarget script aktivert?</li>
									<li>
										Er samtykkeskjema for leads aktivert?
									</li>
									<li>
										Er buttons for konvertering definert og
										merket?
									</li>
									<li>
										Er det tatt høyde for produksjonstid av
										landingsside etter mottatt materiell
										(web)
									</li>
								</ul>
							</div>
						)}
					</div>

					{/* Accordion 4 */}
					<div className='accordion-item'>
						<div
							className={`accordion-title ${
								activeAccordion === 4 ? 'active' : ''
							}`}
							onClick={() => toggleAccordion(4)}
						>
							<h3>Rapportering</h3>
						</div>
						{activeAccordion === 4 && (
							<div className='accordion-content'>
								<p>
									Behov: Hvilket nivå skal det rapporteres på
								</p>

								<ul>
									<li>
										Standard: Kampanjerapport fra eksterne
										kanaler uten innsikt kr 2.500
									</li>
									<li>
										Standard med innsikt: Kampanjerapport
										fra eksterne kanaler med innsikt kr
										3.000
									</li>
									<li>
										Stilling: Kampanjerapport med
										kanalspesifikke tall kr 1.600
									</li>
								</ul>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default CheckList;
