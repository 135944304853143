import React, { useEffect, useState } from 'react';
import CheckboxComponent from '../CheckboxComponent';
import DatePicker from '../DatePicker';
import InputComponent from '../InputComponent';
import TextareaComponent from '../TextareaComponent';

import ContentPackage from '../packages/contentPackage'; // Keeping the new content package

import '../../assets/scss/innholdsbestilling.scss';

// Images
const PodcastImage = require('../../assets/images/podcast.png');

const Innholdsproduksjon = ({
	formData,
	handleChange,
	sections,
	setFormData,
}) => {
	const [selectedRadio, setSelectedRadio] = useState(null);

	const contentPlacement = [
		{
			label: 'Content Premium',
			name: 'contentpremium',
		},
		{
			label: 'Content Standard',
			name: 'contentstandard',
		},
		{
			label: 'Premium Norge (Obs! Gule annonser)',
			name: 'premiumnorge',
		},
		{
			label: 'Native (OBS! gule annonser)',
			name: 'native',
		},
		{
			label: 'Annet (Skriv i fritekstfeltet under)',
			name: 'annet',
		},
	];

	// No need to keep package-related arrays or code related to the old packages.

	useEffect(() => {}, [sections, setFormData]);

	// Handle changes for the remaining inputs
	const handleInnholdsproduksjonChange = (e, index = null) => {
		const { name, value, type, checked } = e.target;
		const updateValue = type === 'checkbox' ? checked : value;

		// Always handle the change for all inputs
		handleChange({
			target: {
				name,
				value: updateValue,
			},
		});

		if (name === 'contentrosenummer') {
			let updatedRosenummere = [...formData.contentrosenummer];
			updatedRosenummere[index] = updateValue; // Use updateValue, which is already computed
			setFormData((prevState) => ({
				...prevState,
				contentrosenummer: updatedRosenummere,
			}));
		}
	};

	// No need for formatPrice since it's package-specific

	const addRosenummerField = () => {
		setFormData({
			...formData,
			contentrosenummer: [...formData.contentrosenummer, ''],
		});
	};

	return (
		<section className='innholdsproduksjon'>
			<div className='innholdsbestilling column'>
				{/* Here we are keeping only the new ContentPackage */}
				<div className='innholdpackages'>
					<ContentPackage setFormData={setFormData} />
				</div>

				{/* General Inputs for 'Annet' or extra options */}
				<CheckboxComponent
					label='Annet eller tillegg'
					type='checkbox'
					name='innholdAnnet'
					className='innholdAnnet'
					value='Annet eller tillegg'
					onChange={handleInnholdsproduksjonChange}
				/>

				<TextareaComponent
					label='Fritekst'
					cols='40'
					rows='5'
					name='innholdOther'
					value={formData.innholdOther}
					onChange={handleInnholdsproduksjonChange}
					className='innholdOtherFree'
				/>

				<small className='freeTextInfo'>
					Om du ikke har solgt en pakke på produksjon, kan du beskrive
					hva det er du bestiller i fritekstfeltet. Hvis du ønsker noe
					annet i tillegg til en pakke, kan du beskrive tillegget.
				</small>

				{/* Price and Viewings Section */}
				<h3>Pris og Visninger</h3>
				<div className='priceAndShowing'>
					<DatePicker
						name='innholdContentDateFrom'
						label='Dato fra:'
						className='contentDateFrom'
						handleChange={handleInnholdsproduksjonChange}
						required
					/>
					<DatePicker
						name='innholdContentDateTo'
						label='Dato til:'
						className='contentDateTo'
						handleChange={handleInnholdsproduksjonChange}
					/>
					<InputComponent
						label='Antall visninger'
						type='number'
						name='innholdContentNumShowing'
						value={formData.contentNumShowing}
						className='contentNumShowing'
						onChange={handleInnholdsproduksjonChange}
					/>
					<InputComponent
						label='Total mediaspend'
						type='number'
						name='innholdContentMediaspend'
						value={formData.contentMediaspend}
						className='contentMediaSpend'
						onChange={handleInnholdsproduksjonChange}
					/>
					<InputComponent
						label='Publiseringssted (Mediehus):'
						type='text'
						name='innholdContentPublishing'
						value={formData.contentPublishing}
						className='contentPublishing'
						onChange={handleInnholdsproduksjonChange}
					/>
				</div>
			</div>

			{/* Anticipated Ad Placement Section */}
			<div className='contentPlacement'>
				<h3>Antatt plassering av annonser</h3>
				<div className='placementCheckContainer'>
					{contentPlacement.map((placement, index) => (
						<CheckboxComponent
							label={placement.label}
							key={index}
							type='checkbox'
							name={placement.name}
							value={placement.label}
							onChange={handleInnholdsproduksjonChange}
						/>
					))}
				</div>

				{formData.annet && (
					<TextareaComponent
						label='Hvis annet er valgt'
						rows='5'
						cols='40'
						name='placementothertext'
						className='placementothertext'
						value={formData.placementOtherText}
						onChange={handleInnholdsproduksjonChange}
					/>
				)}
			</div>

			{/* Additional Information Section */}
			<div className='contentOtherInfo'>
				<h2>Annen informasjon</h2>
				<div className='otherInfoContainer'>
					<div className='rosenumberContainer'>
						{Array.isArray(formData.contentrosenummer) &&
							formData.contentrosenummer.map(
								(rosenummer, index) => (
									<InputComponent
										key={index}
										label={`Rosenummer ${index + 1}`}
										required
										name='contentrosenummer'
										value={rosenummer}
										onChange={(e) =>
											handleInnholdsproduksjonChange(
												e,
												index
											)
										}
										type='text'
										className='contentrosenummer'
									/>
								)
							)}
						<button
							type='button'
							onClick={addRosenummerField}
							className='addRosenummerBtn'
						>
							Legg til et nytt Rosenummer
						</button>
					</div>

					<TextareaComponent
						rows='5'
						cols='40'
						label='Kontaktinfo til kunde'
						name='contactinfocustomer'
						className='contactinfocustomer'
						value={formData.contactinfocustomer}
						onChange={handleInnholdsproduksjonChange}
					/>
					<TextareaComponent
						rows='5'
						cols='40'
						label='Antatt lokasjon'
						name='contentlocation'
						className='contentlocation'
						value={formData.contentlocation}
						onChange={handleInnholdsproduksjonChange}
						required
					/>
				</div>
			</div>
		</section>
	);
};

export default Innholdsproduksjon;
