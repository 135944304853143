import React from 'react';
import '../../assets/scss/landingpages.scss';

const LandingPage = ({ formData, handleChange, setFormData }) => {
	const handleLandingChange = (e, index = null) => {
		const { name, value, type, checked } = e.target;
		const updateValue = type === 'checkbox' ? checked : value;

		if (name === 'landingpages') {
			let updatedLandingpages = [...formData.landingpages];
			updatedLandingpages[index] = updateValue;
			setFormData((prevState) => ({
				...prevState,
				landingpages: updatedLandingpages,
			}));
		} else {
			handleChange({
				target: {
					name,
					value: updateValue,
				},
			});
		}
	};

	const addLandingField = () => {
		setFormData({
			...formData,
			landingpages: [...formData.landingpages, ''],
		});
	};

	return (
		<section className='landingPage'>
			<h2>Landingssider</h2>

			<div className='landingpages'>
				<label>Landingsside(r)</label>
				{Array.isArray(formData.landingpages) &&
					formData.landingpages.map((landingpage, index) => (
						<input
							key={index}
							type='url'
							placeholder='Landingsside. F.eks: https://adressa.no.'
							name='landingpages'
							value={landingpage}
							onChange={(e) => handleLandingChange(e, index)}
							className='landingInput'
						/>
					))}
				<span className='landingInfoSpan'>
					<p>
						Legg kun URL til landingsside, Brief skrives lengre ned
						på siden.
					</p>
				</span>
				<button
					type='button'
					onClick={addLandingField}
					className='addLandingBtn'
				>
					Legg til enda en Landingsside
				</button>
			</div>
		</section>
	);
};

export default LandingPage;
