import React, { useEffect, useState } from 'react';

const Scraped = () => {
	const [services, setServices] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Fetch the scraped data from the backend
		fetch(`${process.env.REACT_APP_API_URL}/api/scrape-prices`)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				console.log('Received data:', data);
				const servicesObject = {};
				data.prices.services.forEach((service) => {
					servicesObject[service.name] = service.price;
				});
				setServices(servicesObject);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching scraped data: ', error);
				setLoading(false);
			});
	}, []);

	return (
		<div className='sraped-test'>
			<h1>Tester scraping</h1>
			{loading ? (
				<p>Loading...</p>
			) : (
				<ul>
					{Object.entries(services).map(([name, price], index) => (
						<li key={index}>
							{name}: {price}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Scraped;
