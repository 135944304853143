import React from 'react';
import '../assets/scss/generell.scss';
import CheckboxComponent from './CheckboxComponent';

const GenerellOrders = ({ formData, handleChange }) => {
	return (
		<section className='generalOrders'>
			<h2>Hva ønsker du å bestille?</h2>
			<div className='general-order-container'>
				<CheckboxComponent
					label='Innholdsproduksjon'
					type='checkbox'
					name='innholdsproduksjon'
					onChange={handleChange}
					value='Innholdsproduksjon'
				/>

				{formData.innholdsproduksjon &&
					formData.typeOfOrder === 'Stilling' &&
					formData.clientRammeavtale && (
						<CheckboxComponent
							label='Podcast'
							type='checkbox'
							name='innholdpodcast'
							onChange={handleChange}
							value='Podcast'
							className='innholdpodcast'
						/>
					)}
				<CheckboxComponent
					label='Eksterne kanaler'
					type='checkbox'
					name='eksterneKanaler'
					onChange={handleChange}
					value='Eksterne kanaler'
				/>
				<CheckboxComponent
					label='Grafisk produksjon'
					type='checkbox'
					name='grafiskProduksjon'
					onChange={handleChange}
					value='Grafisk produksjon'
				/>
				<CheckboxComponent
					label='Web'
					type='checkbox'
					name='web'
					onChange={handleChange}
					value='Web'
				/>
				<CheckboxComponent
					label='Rapport og analyse'
					type='checkbox'
					name='rapportAnalyse'
					onChange={handleChange}
					value='Rapport og analyse'
				/>
			</div>
		</section>
	);
};

export default GenerellOrders;
