import nb from 'date-fns/locale/nb';
import React from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import CheckboxComponent from './CheckboxComponent';
import DatePicker from './DatePicker';
import InputComponent from './InputComponent';

registerLocale('nb', nb);

const PeriodsSection = ({
	formData,
	handleChange,
	platform,
	addPeriod,
	removeLastPeriod,
}) => {
	return (
		<div>
			{Array.isArray(formData.periods[platform]) &&
				formData.periods[platform].map((period, index) => (
					<div className='facePeriods' key={index}>
						<h3>Periode {index + 1}</h3>
						<div className='periods'>
							<div className='periodDates'>
								<DatePicker
									name={`${platform}DateFrom`}
									label='Dato fra'
									className={`${platform}DateFrom`}
									handleChange={(e) =>
										handleChange(e, index, platform)
									}
									required
									locale='nb'
									dateFormat='dd/MM/yyyy'
								/>
								<DatePicker
									name={`${platform}DateTo`}
									label='Dato til'
									className={`${platform}DateTo`}
									handleChange={(e) =>
										handleChange(e, index, platform)
									}
									required
									locale='nb'
									dateFormat='dd/MM/yyyy'
								/>
							</div>

							<div className='spending'>
								<InputComponent
									label='Spending'
									type='number'
									name={`${platform}Spending`}
									value={period.spending}
									className={`${platform}Spending`}
									onChange={(e) =>
										handleChange(e, index, platform)
									}
									required
									dateFormat='nb'
								/>
								<CheckboxComponent
									label='Kunde står for mediaspending'
									type='checkbox'
									name={`${platform}clientspending`}
									className={`${platform}clientspending`}
									onChange={(e) =>
										handleChange(e, index, platform)
									}
									value={true}
								/>
							</div>
							<div className='techFee'>
								<InputComponent
									label='Adm. gebyr eksterne kanaler (TechFee)'
									type='number'
									name={`${platform}TechFee`}
									value={
										period.spending
											? Math.round(period.spending * 0.1)
											: 0
									}
									className={`${platform}TechFee`}
									onChange={(e) =>
										handleChange(e, index, platform)
									}
									readOnly={true}
								/>
								<small>
									TechFee kan ikke redigeres. Denne oppdateres
									med 10% av spending
								</small>
							</div>
						</div>
					</div>
				))}
			<div className='periodBtns'>
				<button
					className='addPeriods'
					type='button'
					onClick={() => addPeriod(platform)}
				>
					Legg til periode
				</button>
				{formData.periods[platform].length >= 2 && (
					<button
						className='removePeriods'
						type='button'
						onClick={() => removeLastPeriod(platform)}
					>
						Ta bort siste periode
					</button>
				)}
			</div>
		</div>
	);
};

export default PeriodsSection;
