import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../services/UserContext';

// CSS
import '../assets/scss/loginPrompt.scss';

const LoginPrompt = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loginMessage, setLoginMessage] = useState('');
	const [forgotPassword, setForgotPassword] = useState(false);
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const { setUser } = useContext(UserContext);

	const toggleForgotPassword = () => {
		setForgotPassword(!forgotPassword);
	};

	const handleForgotPassword = () => {
		setIsLoading(true);
		// Endpoint URL
		const endpoint = `${process.env.REACT_APP_API_URL}/api/users/forgot-password`;

		// Make a POST request to the /forgot-password endpoint
		fetch(endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }), // Send the email from the state
		})
			.then((response) => response.json())
			.then((data) => {
				// Check if there's a success message from the backend
				if (data.message) {
					setLoginMessage(data.message); // Update the login message to inform the user

					// Wait for 2 seconds, then toggle the forgotPassword state
					setTimeout(() => {
						setForgotPassword(false);
						setLoginMessage(''); // Reset the login message
					}, 2000);
				} else {
					setLoginMessage(
						'Det er et problem med tjenesten. Prøv igjen senere.'
					);
				}
			})
			.catch((error) => {
				console.error('Error during password reset:', error);
				setLoginMessage(
					'Et problem oppstod ved tilbakestilling av passordet. Prøv igjen senere.'
				);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleLogin = () => {
		fetch(`${process.env.REACT_APP_API_URL}/api/users/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ usernameOrEmail: username, password }),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.token) {
					localStorage.setItem('token', data.token);
					localStorage.setItem('userId', data.userId);
					localStorage.setItem('userRole', data.userrole);
					localStorage.setItem('first_name', data.first_name);
					localStorage.setItem('last_name', data.last_name);
					localStorage.setItem('email', data.email);
					setLoginMessage('Login successful!'); // Set login message

					setUser({
						isLoggedIn: true,
						role: data.userrole,
						first_name: data.first_name,
						last_name: data.last_name,
						email: data.email, // assuming the backend returns the userrole in the login response
					});

					// Wait for 2 seconds, then navigate to the form
					setTimeout(() => {
						navigate('/'); // Navigate back to the form (or desired route)
					}, 1000);
				} else {
					setLoginMessage('Invalid credentials');
				}
			})
			.catch((error) => {
				console.error('Error during login:', error);
			});
	};

	return (
		<div className='loginPrompt'>
			{!forgotPassword ? (
				<>
					<h1>Login</h1>
					<p>
						Vennligst logg inn med brukernavn og passord du har
						mottatt på Epost / Teams
					</p>
					<p>
						Du vil ikke kunne bruke skjema før du har logget deg
						inn.
					</p>
					<div className='loginForm'>
						<input
							type='text'
							placeholder='Brukernavn eller Epost'
							onChange={(e) => setUsername(e.target.value)}
						/>
						<input
							type='password'
							placeholder='Passord'
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div className='loginBtnMsg'>
						<div>
							<button onClick={handleLogin}>Login</button>
							<button
								onClick={toggleForgotPassword}
								className='toggleforgotbtn'
							>
								{forgotPassword
									? 'Back to Login'
									: 'Glemt passord?'}
							</button>
						</div>
						{loginMessage && <p>{loginMessage}</p>}
					</div>
				</>
			) : (
				<>
					<h1>Glemt passord</h1>
					<p>
						Skriv inn Epost koblet til din bruker og trykk på
						"Tilbakestill passord"
					</p>
					<p>
						Du vil da motta en Epost med et nytt midlertidig
						passord.
					</p>
					<div className='loginForm'>
						<label htmlFor='forgotpasswordfield'>Epost</label>
						<input
							type='email'
							placeholder='Epost'
							onChange={(e) => setEmail(e.target.value)}
							id='forgotpasswordfield'
						/>
					</div>
					<div className='loginBtnMsg'>
						<div>
							<button
								onClick={handleForgotPassword}
								className='restorepasswordbtn'
								disabled={isLoading}
							>
								{isLoading
									? 'Laster...'
									: 'Tilbakestill passord'}
							</button>
							<button onClick={toggleForgotPassword}>
								Tilbake til Login
							</button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default LoginPrompt;
