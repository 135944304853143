import React, { useEffect, useState } from 'react';
import '../../assets/scss/userProfile.scss';

const Profile = () => {
	const userId = localStorage.getItem('userId');
	const [profileData, setProfileData] = useState(null);
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [selectedFile, setSelectedFile] = useState(null);

	// New function to handle file input change
	const handleFileChange = (e) => {
		setSelectedFile(e.target.files[0]);
	};

	// New function to handle profile picture update
	const handleProfilePictureUpdate = () => {
		const formData = new FormData();
		formData.append('profilePicture', selectedFile);
		formData.append('id', userId); // Send the user ID

		fetch(
			`${process.env.REACT_APP_API_URL}/api/users/update-profile-picture`,
			{
				method: 'POST',
				body: formData,
			}
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.message === 'Profile picture updated successfully') {
					// Update the profile picture in the state
					setProfileData({
						...profileData,
						profilePicture: URL.createObjectURL(selectedFile),
					});
				}
			})
			.catch((error) => {
				console.error('Error updating profile picture:', error);
			});
	};

	useEffect(() => {
		console.log('Sending userId:', userId);
		fetch(`${process.env.REACT_APP_API_URL}/api/users/profile`, {
			headers: {
				userId: userId, // Send the user ID in headers or any other way you prefer
			},
		})
			.then((res) => res.json())
			.then((data) => setProfileData(data))
			.catch((err) => console.error('Error fetching profile:', err));
	}, [userId]);

	const imageUrl = profileData?.profile_picture
		? profileData.profile_picture.replace(/\\/g, '/')
		: null;

	const handlePasswordChange = () => {
		fetch(`${process.env.REACT_APP_API_URL}/api/users/update-password`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username: profileData.username,
				newPassword,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.message === 'Password updated') {
					alert('Password updated successfully');
					setShowPasswordModal(false);
				}
			})
			.catch((error) => {
				console.error('Error updating password:', error);
			});
	};

	const development = false;

	return (
		<div className='profile-container'>
			<div className='profileInfo'>
				{profileData ? (
					<>
						<div className='profile-header'>
							<h1>{profileData.username}'s Profile</h1>
							<button
								className='change-password-btn'
								onClick={() => setShowPasswordModal(true)}
							>
								Endre passord
							</button>
						</div>
						<div className='profile-details'>
							{/* <div className='profile-picture'>
								{imageUrl && (
									<img
										src={`${process.env.REACT_APP_API_URL}/${imageUrl}`}
										alt='Profile'
									/>
								)}
								<input type='file' onChange={handleFileChange} />
								<button onClick={handleProfilePictureUpdate}>
									Oppdater profilbildet
								</button>
							</div> */}
							<div className='profile-info'>
								<p>
									<strong>Epost:</strong> {profileData.email}
								</p>
								<p>
									<strong>Fornavn:</strong>{' '}
									{profileData.first_name || 'Firstname'}
								</p>
								<p>
									<strong>Etternavn:</strong>{' '}
									{profileData.last_name || 'Lastname'}
								</p>
							</div>
						</div>
						{showPasswordModal && (
							<div className='password-modal'>
								<h2>Endre passord</h2>
								<input
									type='password'
									placeholder='New Password'
									value={newPassword}
									onChange={(e) =>
										setNewPassword(e.target.value)
									}
								/>
								<div className='modal-buttons'>
									<button onClick={handlePasswordChange}>
										Submit
									</button>
									<button
										onClick={() =>
											setShowPasswordModal(false)
										}
									>
										Cancel
									</button>
								</div>
							</div>
						)}
					</>
				) : (
					<p>Loading...</p>
				)}
			</div>

			{development && (
				<div className='salesStats'>
					<div className='salesStats__header'>
						<h2>Din statistikk</h2>
					</div>

					<div className='salesStats__container'>
						<select name='salesStatsOptions' id='salesStatsOptions'>
							<option value=''>Velg hva du vil se</option>
							<option value='pdf'>Tidligere ordre</option>
							<option value='stats'>Min statistikk</option>
						</select>
					</div>
				</div>
			)}
		</div>
	);
};

export default Profile;
