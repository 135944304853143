import Chart from 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';

// CSS
import '../../assets/scss/statistics.scss';

const FormStatistics = () => {
	const [submissions, setSubmissions] = useState([]);
	const [numForms, setNumForms] = useState(0);
	const [salesUserCount, setSalesUserCount] = useState(0);
	const [generellOrderCount, setGenerellOrderCount] = useState(0);
	const [stillingOrderCount, setStillingOrderCount] = useState(0);

	const [selectedMonth, setSelectedMonth] = useState(
		new Date().getMonth() + 1
	); // Default to current month
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
	const [selectedStat, setSelectedStat] = useState('techFee'); // Default statistic

	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: 'Antall bestillinger sendt',
				data: [],
				backgroundColor: 'rgba(75, 192, 192, 0.6)',
			},
		],
	});

	// ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

	// Count number of forms sent by user
	const fetchNumUserFormSent = () => {
		fetch(`${process.env.REACT_APP_API_URL}/api/users/salgFormCounts`)
			.then((res) => res.json())
			.then((data) => {
				const usernames = data.map((user) => user.username);
				const formCounts = data.map((user) => user.form_count);

				setChartData({
					labels: usernames,
					datasets: [
						{
							label: 'Antall bestillinger sendt',
							data: formCounts, // Set it to formCounts instead of []
							backgroundColor: 'rgba(75, 192, 192, 0.6)',
						},
					],
				});
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
			});
	};

	// Antall skjema sendt
	const fetchFormCount = () => {
		fetch(`${process.env.REACT_APP_API_URL}/api/numFormsSent`)
			.then((res) => res.json())
			.then((data) => {
				setNumForms(data.count);
			})
			.catch((error) =>
				console.error('Error fetching number of forms:', error)
			);
	};

	const fetchNumSales = () => {
		// Fetch users with the role "sales" to display number of sales people
		fetch(`${process.env.REACT_APP_API_URL}/api/users/numSalesUsers`)
			.then((response) => response.json())
			.then((data) => {
				setSalesUserCount(data.count);
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
			});
	};

	const fetchSpecificOrderCounts = () => {
		fetch(
			`${process.env.REACT_APP_API_URL}/api/statistics/numSpecificOrders`
		)
			.then((response) => response.json())
			.then((data) => {
				setGenerellOrderCount(data.generellCount);
				setStillingOrderCount(data.stillingCount);
			})
			.catch((error) => {
				console.error(
					'Error fetching number of specific orders:',
					error
				);
			});
	};

	useEffect(() => {
		// Replace with your actual backend URL and endpoint
		fetch(`${process.env.REACT_APP_API_URL}/api/statistics/all-submissions`)
			.then((response) => response.json())
			.then((data) => {
				setSubmissions(data);
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
			});
		fetchFormCount();

		fetchFormCount();
		fetchNumSales();
		fetchSpecificOrderCounts();
		fetchNumUserFormSent();

		const intervalId = setInterval(() => {
			fetchFormCount();
			fetchNumSales();
			fetchSpecificOrderCounts();
			fetchNumUserFormSent();
		}, 10000);
		return () => clearInterval(intervalId);
	}, []);

	// PIE Chart
	const diffOrdersData = {
		labels: ['Generell', 'Stilling'],
		datasets: [
			{
				label: 'Antall bestillinger: ',
				data: [generellOrderCount, stillingOrderCount],
				backgroundColor: ['#FF6384', '#36A2EB'],
				hoverBackgroundColor: ['#FF6384', '#36A2EB'],
			},
		],
	};

	// PIE chart options
	const options = {
		maintainAspectRatio: true,
		responsive: true,
		plugins: {
			legend: {
				labels: {
					font: {
						size: 20,
					},
				},
			},
		},
	};

	// Filter calculations
	const formatNorwegianCurrency = (number) => {
		return number.toLocaleString('no-NO');
	};

	const calculateTechFeeDataForMonth = () => {
		let totalTechFee = 0;
		let formsWithTechFee = 0;

		// Loop through each submission
		submissions.forEach((submission) => {
			const formData = submission.form_data;
			let foundTechFeeInForm = false;

			// Loop through each platform in the periods object
			for (let platform in formData.periods) {
				formData.periods[platform].forEach((period) => {
					if (period.techFee && period.techFee > 0) {
						totalTechFee += period.techFee;
						foundTechFeeInForm = true;
					}
				});
			}

			if (foundTechFeeInForm) {
				formsWithTechFee++;
			}
		});

		return {
			totalTechFee,
			totalForms: submissions.length,
			formsWithTechFee,
		};
	};

	const calculateUserTechFeeData = () => {
		const userTechFeeMapping = {};

		// Loop through each submission
		submissions.forEach((submission) => {
			const formData = submission.form_data;
			const userId = submission.user_id;

			// Check for undefined or missing first_name and last_name
			const userFirstName = formData.firstName || 'Unknown';
			const userLastName = formData.lastName || 'Unknown';
			const userFullName = `${userFirstName} ${userLastName}`;

			if (!userTechFeeMapping[userId]) {
				userTechFeeMapping[userId] = {
					fullName: userFullName,
					totalTechFee: 0,
				};
			}

			// Loop through each platform in the periods object
			for (let platform in formData.periods) {
				formData.periods[platform].forEach((period) => {
					if (period.techFee && period.techFee > 0) {
						userTechFeeMapping[userId].totalTechFee +=
							period.techFee;
					}
				});
			}
		});

		return userTechFeeMapping;
	};

	const fetchFilteredData = () => {
		fetch(
			`${process.env.REACT_APP_API_URL}/api/statistics/submissions-by-month-year?month=${selectedMonth}&year=${selectedYear}`
		)
			.then((response) => response.json())
			.then((data) => {
				setSubmissions(data);
				calculateTechFeeDataForMonth();
				calculateUserTechFeeData();
			})
			.catch((error) => {
				console.error('Error fetching filtered data:', error);
			});
	};

	return (
		<div className='statistics column'>
			<div className='row'>
				<div className='forms-sent'>
					<h2>Antall skjema sendt</h2>
					<div className='number'>
						<p className='numFormCount'>{numForms}</p>
					</div>
				</div>
				<div className='numSalesPersons'>
					<h2>Antall selgere</h2>
					<div className='number'>
						<p className='numSalesCount'>{salesUserCount}</p>
					</div>
				</div>
			</div>

			<div className='row'>
				<div className='diffOrders'>
					<h2>Antall Generelle / Stilling bestillinger</h2>
					<Pie data={diffOrdersData} options={options} />
				</div>
				<div className='numFormsSentByUser'>
					<h2>Antall bestillinger hver selger har sendt</h2>
					{chartData &&
						chartData.labels &&
						chartData.labels.length > 0 && <Bar data={chartData} />}
				</div>
			</div>
			<div>
				<div className='filterStats'>
					<div className='filter'>
						<select
							value={selectedMonth}
							onChange={(e) => setSelectedMonth(e.target.value)}
						>
							<option value='1'>Januar</option>
							<option value='2'>Februar</option>
							<option value='3'>Mars</option>
							<option value='4'>April</option>
							<option value='5'>Mai</option>
							<option value='6'>Juni</option>
							<option value='7'>Juli</option>
							<option value='8'>August</option>
							<option value='9'>September</option>
							<option value='10'>Oktober</option>
							<option value='11'>November</option>
							<option value='12'>Desember</option>
						</select>

						<select
							value={selectedYear}
							onChange={(e) => setSelectedYear(e.target.value)}
						>
							{[...Array(new Date().getFullYear() - 2022)].map(
								(_, index) => {
									const year = 2023 + index;
									return (
										<option key={year} value={year}>
											{year}
										</option>
									);
								}
							)}
						</select>

						<select
							value={selectedStat}
							onChange={(e) => setSelectedStat(e.target.value)}
						>
							<option value='techFee'>TechFee</option>
							<option value='numForms'>
								Antall skjema og selgere
							</option>
						</select>

						<button onClick={fetchFilteredData}>Hent data</button>
					</div>
					<div className='viewFilterStats'>
						{selectedStat === 'techFee' && (
							<div className='calcTechFee'>
								<div>
									<h3>
										Total techFee for {selectedMonth}/
										{selectedYear}:
									</h3>
									<p>
										kr{' '}
										{formatNorwegianCurrency(
											calculateTechFeeDataForMonth()
												.totalTechFee
										)}{' '}
										,-
									</p>
								</div>
								<div>
									<h3>
										Antall skjema sendt inn i perioden{' '}
										{selectedMonth}/{selectedYear}:
									</h3>
									<p>
										{
											calculateTechFeeDataForMonth()
												.totalForms
										}
									</p>
								</div>
								<div>
									<h3>
										Antall skjema i perioden med techFee{' '}
										{selectedMonth}/{selectedYear}:
									</h3>
									<p>
										{
											calculateTechFeeDataForMonth()
												.formsWithTechFee
										}
									</p>
								</div>
							</div>
						)}
						{selectedStat === 'numForms' && (
							<div className='numFormsTech'>
								<div className='totalForms'>
									<h3>
										Totalt antall skjema sendt inn i
										perioden {selectedMonth}/{selectedYear}:
									</h3>
									<p>{submissions.length}</p>
								</div>

								<div className='usersTech'>
									<h3>Selgere og techFee:</h3>
									<p>
										Hvem av selgerne har fått inn mest
										techFee
									</p>
									<ul>
										{Object.values(
											calculateUserTechFeeData()
										).map((user) => (
											<li key={user.fullName}>
												{user.fullName} -{' '}
												<b>
													kr{' '}
													{formatNorwegianCurrency(
														user.totalTechFee
													)}{' '}
													,-
												</b>
											</li>
										))}
									</ul>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormStatistics;
