import React from 'react';
import CheckboxComponent from '../CheckboxComponent';
import '../../assets/scss/formBottom.scss';
import TextareaComponent from '../TextareaComponent';
import DatePicker from '../DatePicker';


const FormBottom = ({ formData, handleChange }) => {
    const isKorrNotNeeded = formData.korrNotNeeded === "Trenger ikke korrektur";
    const isKorrSameSeller = formData.korr_same_seller;

    let korrReceiverValue = formData.korrReceiver;
    if (isKorrSameSeller) {
        korrReceiverValue = formData.email;
    }

    return (

        <section className="formBottomSection">
            <h2>Brief / Manus</h2>
            <div className="brief column">
                <TextareaComponent 
                    label="Brief / Manus"
                    name="briefManus"
                    value={formData.briefManus}
                    onChange={handleChange}
                    className="briefManusField"
                    cols="40"
                    rows="6"
                    required
                />
                <small className="briefInfo">
                    Fyll inn nødvendig informasjon for å utføre oppdraget.
                    F.eks: har du/kunden ønsker til utforming, hva er målgruppen, hva er budskapet, hva er hensikten med kampanjen, er det en fargepalett som skal brukes.
                </small>

                <p className="materiell">
                    Materiell lastes opp via IX! (Gjelder ikke Innholdsproduksjon). <br />
                    Materiell sendes til <a href="mailto:studio@adresseavisen.no">studio@adresseavisen.no</a>
                </p>
            </div>

            <h2>Korrektur</h2>
            <div className="korrektur column">
                <DatePicker 
                    name="korr_date"
                    label="Korrekturdato"
                    className="korrektur_dato"
                    handleChange={handleChange}
                    required={!isKorrNotNeeded}
                />
                <TextareaComponent 
                    cols="40"
                    rows="5"
                    label="Hvem skal ha korrektur"
                    name="korr_receiver"
                    value={formData.korrReceiver}
                    onChange={handleChange}
                    className="korrReceiver"
                    required={!isKorrNotNeeded}
                />
                <small>
                    Skriv inn Epost til de som skal ha korrektur. Separer hver Epost med "," (Komma)
                </small>

                <CheckboxComponent 
                    label="Korrektur er samme som bestiller"
                    type="checkbox"
                    name="korr_same_seller"
                    onChange={handleChange}
                    className="korrSameSeller"
                    value={true}
                />
                <CheckboxComponent 
                    label="Trenger ikke korrektur"
                    type="checkbox"
                    name="korrNotNeeded"
                    onChange={handleChange}
                    className="korrNotNeeded"
                    value="Trenger ikke korrektur"
                />
            </div>
        </section>

    )
}


export default FormBottom;