import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/header.scss';

import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../services/UserContext';
// import Snowfall from '../utility/Snowfall';

// LOGO
import Logo from '../assets/images/logo.svg';

const Header = ({ totalPrice }) => {
	// const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
	const { user, setUser } = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(user.isLoggedIn);
	// const [_profileData, setProfileData] = useState(null);

	const navigate = useNavigate();

	const location = useLocation();
	const isFrontPage = location.pathname === '/';
	const headerClass = isFrontPage ? 'fixed-header' : '';

	useEffect(() => {
		if (user.isLoggedIn) {
			const userId = localStorage.getItem('userId');
			if (userId) {
				fetch(`${process.env.REACT_APP_API_URL}/api/users/profile`, {
					headers: {
						userId: userId,
					},
				})
					.then((res) => res.json())
					.then((data) => {
						//   setProfileData(data);
						setUser((prevUser) => ({
							...prevUser,
							role: data.userrole,
						}));
						setIsLoading(false); // Set loading to false once data is fetched
					})
					.catch((err) => {
						console.error('Error fetching profile:', err);
						setIsLoading(false); // Ensure loading is set to false even on error
					});
			}
		}
	}, [user.isLoggedIn]);

	const handleLogout = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('userRole'); // Remove the user role from localStorage on logout
		setUser({
			isLoggedIn: false,
			role: null,
		});
		navigate('/');
	};

	return (
		<div className={`main-header ${headerClass}`}>
			{isLoading ? (
				<p>Laster inn...</p>
			) : (
				<>
					<div className='logo'>
						<Link to='/'>
							<img src={Logo} alt='logo' />
						</Link>
					</div>
					<ul>
						<li>
							<Link to='/'>Skjema</Link>
						</li>
						{/* <li>
							<Link to='/change-order'>Endre ordre</Link>
						</li> */}
						<li>
							<Link to='/checklist'>Sjekkliste</Link>
						</li>
						<li>
							<Link to='/order-routines'>Bestillingsrutiner</Link>
						</li>
						{user && user.isLoggedIn && user.role === 'admin' ? (
							<li>
								<Link to='/control-panel'>Kontrollpanel</Link>
							</li>
						) : null}
						{user.isLoggedIn ? (
							<>
								<li>
									<Link to='/profile'>Brukerprofil</Link>
								</li>
								<li>
									<button onClick={handleLogout}>
										Logg ut
									</button>
								</li>
							</>
						) : null}
						{/* <li>
							<span>Shopping Cart: {totalPrice} NOK</span>
						</li> */}
					</ul>
				</>
			)}
			{/* <Snowfall numSnowflakes={200} />{' '} */}
		</div>
	);
};

export default Header;
