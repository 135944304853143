import React, { useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi'; // Importing the question mark icon
import '../../assets/scss/additionalServices.scss';
import { additionalPackages, relansering } from './packageData'; // Import relansering

const AdditionalServices = ({ selectedPackage, setFormData }) => {
	const [selectedServices, setSelectedServices] = useState([]);
	const [modalData, setModalData] = useState(null); // For holding modal data
	const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

	if (!selectedPackage) {
		return null;
	}

	// Use relansering if selected package is "resalgspakker", otherwise use additionalPackages
	const packagesToShow =
		selectedPackage === 'resalgspakke' ? relansering : additionalPackages;

	const formatCurrency = (amount, isFrom) => {
		const formattedPrice = new Intl.NumberFormat('no-NO').format(amount);
		return isFrom ? `Fra ${formattedPrice},-` : `${formattedPrice},-`;
	};

	const handleServiceSelect = (id) => {
		const selectedService = packagesToShow.find(
			(packageData) => packageData.id === id
		);

		const isServiceSelected = selectedServices.some(
			(service) => service.id === selectedService.id
		);

		const newSelectedServices = isServiceSelected
			? selectedServices.filter(
					(service) => service.id !== selectedService.id
			  )
			: [
					...selectedServices,
					{ id: selectedService.id, name: selectedService.name },
			  ];

		setSelectedServices(newSelectedServices);

		// Update formData to include the names and ids of selected services
		setFormData((prevData) => ({
			...prevData,
			selectedAdditionalServices: newSelectedServices,
		}));
	};

	// Function to open the modal
	const openModal = (data) => {
		setModalData(data);
		setIsModalOpen(true);
	};

	// Function to close the modal
	const closeModal = () => {
		setIsModalOpen(false);
		setModalData(null);
	};

	return (
		<div className='additionalServices'>
			<h3>Tilvalg for {selectedPackage} pakke</h3>
			<div className='content'>
				{packagesToShow.map((packageData) => {
					const displayPrice = packageData.pris
						? formatCurrency(packageData.pris, false)
						: formatCurrency(packageData.from, true);

					const isSelected = selectedServices.some(
						(service) => service.id === packageData.id
					);

					return (
						<div
							key={packageData.id}
							className={`flex ${isSelected ? 'selected' : ''}`}
							onClick={() => handleServiceSelect(packageData.id)}
						>
							<div className='container'>
								<input
									type='checkbox'
									checked={isSelected}
									onChange={() =>
										handleServiceSelect(packageData.id)
									}
									style={{ display: 'none' }} // Hides the checkbox visually
								/>
								<span>{packageData.name}</span>
								<div className='price'>{displayPrice}</div>
							</div>

							{/* Render the question mark icon if the package has a sub or description */}
							{(packageData.sub || packageData.description) && (
								<FiHelpCircle
									className='info-icon'
									onClick={(e) => {
										e.stopPropagation(); // Prevent triggering the selection
										openModal(packageData);
									}}
									style={{
										cursor: 'pointer',
										marginLeft: '10px',
									}}
								/>
							)}
						</div>
					);
				})}
			</div>

			{/* Modal for showing additional information */}
			{isModalOpen && modalData && (
				<div className='modal'>
					<div className='modal-content'>
						<button className='modal-close' onClick={closeModal}>
							&times;
						</button>
						<h3>Beskrivelse</h3>

						{/* Check for isSomePackage to render SoMe-specific modal */}
						{modalData.isSomePackage ? (
							<div className='modal-some-packages'>
								{/* SoMe package modal view */}
								{modalData.sub.map((subItem) => (
									<div
										key={subItem.id}
										className='modal-some-item'
									>
										<div className='modal-some-description'>
											<h4>{subItem.name}</h4>
											<p>{subItem.description}</p>
										</div>
										<div className='modal-some-price'>
											{formatCurrency(
												subItem.pris,
												false
											)}
										</div>
									</div>
								))}
							</div>
						) : modalData.sub ? (
							<div className='modal-sub-table'>
								{/* For packages with "sub" (like driving) */}
								<table>
									<thead>
										<tr>
											<th>Destinasjon</th>
											<th>To produsenter</th>
											<th>En produsent</th>
										</tr>
									</thead>
									<tbody>
										{modalData.sub.map((subItem) => (
											<tr key={subItem.id}>
												<td>{subItem.name}</td>
												<td>{subItem.two}</td>
												<td>{subItem.one}</td>
											</tr>
										))}
									</tbody>
								</table>
								<small>
									* Gjelder når det trengs to produsenter på
									lokasjon. Oppdrag det er tekst, foto og
									video.
								</small>
								<small>
									** Gjelder når det trengs én produsent på
									lokasjon. Oppdrag det er tekst og foto,
									eller kun foto/video
								</small>
								<small>
									Alle priser er eks mva. Andre destinasjoner
									gis på forespørsel.
								</small>
							</div>
						) : modalData.description ? (
							<div
								className='modal-description'
								dangerouslySetInnerHTML={{
									__html: modalData.description,
								}}
							/>
						) : (
							<p>
								No additional details available for this
								service.
							</p>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default AdditionalServices;
