import React, { useState } from 'react';
import '../../assets/scss/contentPackage.scss'; // Assuming you have a corresponding SCSS file
import AdditionalServices from './additionalServices';
import { contentPackages } from './packageData';

const ContentPackage = ({ setFormData }) => {
	const [selectedPackage, setSelectedPackage] = useState(null);

	const formatCurrency = (amount, isFrom) => {
		// Norwegian locale formatting with space for thousands and ",-"
		const formattedPrice = new Intl.NumberFormat('no-NO').format(amount);
		return isFrom ? `Fra ${formattedPrice},-` : `${formattedPrice},-`;
	};

	const handlePackageSelect = (id) => {
		const newSelectedPackage = selectedPackage === id ? null : id;
		setSelectedPackage(newSelectedPackage);

		// Update the formData with the selected package
		setFormData((prevData) => ({
			...prevData,
			selectedContentPackage: newSelectedPackage, // Store selected package
		}));
	};

	return (
		<main className='contentPackages'>
			<div className='contentPackages__content'>
				<h2>Innholdsbestilling</h2>
				<div className='border'></div>
				<div className='titleDesc'>
					<p>Sett sammen pakken som passer deg best</p>
				</div>
				<div className='container'>
					{contentPackages.map((packageData, index) => {
						const isSelected = selectedPackage === packageData.id;
						const displayPrice = packageData.pris
							? formatCurrency(packageData.pris, false)
							: formatCurrency(packageData.from, true);

						return (
							<div key={packageData.id} className='radio-box'>
								<input
									type='radio'
									name='contentPackage'
									id={`package-${index}`}
									value={packageData.id}
									className='hidden-radio'
									checked={isSelected} // Bind checked to isSelected
									onClick={() =>
										handlePackageSelect(packageData.id)
									}
									readOnly // Prevent default behavior (onClick handles everything)
								/>
								<label
									htmlFor={`package-${index}`}
									className={`custom-radio ${
										isSelected ? 'selected' : ''
									}`}
								>
									<div className='content-box'>
										<div className='title'>
											{packageData.name}
										</div>
										<div className='border'></div>
										<ul className='description'>
											<li>{packageData.description}</li>
										</ul>
									</div>
									<div
										className={`order-status ${
											isSelected ? 'chosen' : 'order'
										}`}
									>
										{isSelected
											? 'Pakke valgt'
											: 'Bestill pakke'}
									</div>
									<div className='price'>
										Pris {displayPrice}
									</div>
								</label>
							</div>
						);
					})}
				</div>
			</div>

			{/* Render the additional services section */}
			<AdditionalServices
				selectedPackage={selectedPackage}
				setFormData={setFormData} // Pass setFormData to AdditionalServices
			/>
		</main>
	);
};

export default ContentPackage;
