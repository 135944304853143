import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    
    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/login-prompt');
      }
    }, [isLoggedIn, navigate]);

    return isLoggedIn ? <WrappedComponent {...props} /> : null;
  };
};

export default withAuth;