import React, { useContext } from 'react';
import InputComponent from '../InputComponent';
import UserContext from '../../services/UserContext';

const SalesPerson = ({ formData, handleChange }) => {
    const { user } = useContext(UserContext);
    return (
        <section className="salesSection">
            <h2>Om selger</h2>
            <div className="row">
                <InputComponent
                    label="Fornavn"
                    type="text"
                    name="firstName"
                    value={user.first_name}
                    onChange={handleChange}
                    className="salgFornavn"
                    readOnly
                />

                <InputComponent
                    label="Etternavn"
                    type="text"
                    name="lastName"
                    value={user.last_name}
                    onChange={handleChange}
                    className="salgEtternavn"
                    readOnly
                />

                <InputComponent
                    label="E-post"
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                    className="salgEpost"
                    readOnly
                />
            </div>
        </section>
    );

};


export default SalesPerson;