import React from 'react';
import { Route, Link, Routes } from 'react-router-dom';
import UserManagement from '../UserManagement';
import ControlPanelItems from '../controlPanelItems';
import FormStatistics from './FormStatistics';
import Scraped from './Scraped';

import '../../assets/scss/controlPanel.scss';

const ControlPanel = () => {
  return (
    <div className="controlPanel">
        <nav>
            <h1>Control Panel</h1>
            <ul>
                <li>
                    <Link to='/control-panel'>Kontroll panel</Link>
                </li>
                <li>
                    <Link to='user-management'>Brukerkontroll</Link>
                </li>
                <li>
                    <Link to='form-statistics'>Statistikk</Link>
                </li>
                <li>
                    <Link to='scraped'>Scraped</Link>
                </li>
            </ul>
        </nav>

        <Routes>
            <Route path="/" element={<ControlPanelItems />} />
            <Route path='user-management' element={<UserManagement />} />
            <Route path='form-statistics' element={<FormStatistics />} />
            <Route path='scraped' element={<Scraped />} />
        </Routes>
    </div>
  );
};

export default ControlPanel;