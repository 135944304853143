import 'font-awesome/css/font-awesome.min.css';
import React, { useEffect, useState } from 'react';

const ControlPanelItems = () => {
	const [numUsers, setNumUsers] = useState(0);
	const [numForms, setNumForms] = useState(0);
	const [pdfList, setPdfList] = useState([]);

	const fetchUserCount = () => {
		fetch(`${process.env.REACT_APP_API_URL}/api/users/numUsers`)
			.then((res) => res.json())
			.then((data) => {
				setNumUsers(data.count);
			})
			.catch((error) =>
				console.error('Error fetching number of users:', error)
			);
	};

	const fetchFormCount = () => {
		fetch(`${process.env.REACT_APP_API_URL}/api/numFormsSent`)
			.then((res) => res.json())
			.then((data) => {
				setNumForms(data.count);
			})
			.catch((error) =>
				console.error('Error fetching number of forms:', error)
			);
	};

	const fetchPdf = () => {
		fetch(`${process.env.REACT_APP_API_URL}/api/pdfs/get-pdfs`)
			.then((res) => res.json())
			.then((data) => {
				setPdfList(data);
			})
			.catch((error) => console.error('Error fetching PDF list:', error));
	};

	// Fetch number of users when component mounts
	useEffect(() => {
		fetchUserCount();
		fetchFormCount();
		fetchPdf();

		// Optional: Set an interval to re-fetch the data every 10 seconds
		const intervalId = setInterval(() => {
			fetchUserCount();
			fetchFormCount();
		}, 10000); // 10 seconds

		// Cleanup: Clear the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, []);

	return (
		<div className='control-panel-view'>
			<div className='row'>
				<div className='numUsers'>
					<h2>Antall brukere registrert</h2>
					<p className='numUsersCount'>{numUsers}</p>
				</div>
				<div className='numFormsSent'>
					<h2>Antall skjema sendt</h2>
					<p className='numFormCount'>{numForms}</p>
				</div>
			</div>
			<div className='listPDF'>
				<ul>
					{pdfList.map((pdf) => (
						<li key={pdf.id}>
							<a
								href={`${process.env.REACT_APP_API_URL}/api/pdfs/get-pdf/${pdf.id}`}
								target='_blank'
								rel='noopener noreferrer'
							>
								<i
									className='fa fa-file-pdf-o'
									aria-hidden='true'
								></i>{' '}
								Vis {pdf.filename}
							</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default ControlPanelItems;
