import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateComponent = ({ label, name, className, handleChange, required }) => {
	const [selectedDate, setSelectedDate] = useState(null);

	const handleDateChange = (date) => {
		setSelectedDate(date);
		handleChange({
			target: {
				name,
				value: date,
			},
		});
	};

	return (
		<div className='datePicker'>
			<label htmlFor={name}>
				{label}
				{required && <span style={{ color: 'red' }}> *</span>}
			</label>
			<DatePicker
				selected={selectedDate}
				onChange={handleDateChange}
				openToDate={new Date()}
				isClearable
				placeholderText='Velg en dato'
				name={name}
				className={className}
				id={name}
				required={required}
				dateFormat='dd/MM/yyy'
				autoComplete='off'
			/>
		</div>
	);
};

export default DateComponent;
