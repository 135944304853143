import React, { useEffect, useState } from 'react';
import '../assets/scss/usermanagement.scss';

import Modal from './Modal';

const UserManagement = () => {
	const [users, setUsers] = useState([]);
	const [showForm, setShowForm] = useState(false);
	const [newUsername, setNewUsername] = useState('');
	const [isEditing, setIsEditing] = useState(false);
	const [editingUsername, setEditingUsername] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [message, setMessage] = useState('');
	const [newEmail, setNewEmail] = useState('');
	const [newUserRole, setNewUserRole] = useState('');
	const [newFirstName, setNewFirstName] = useState('');
	const [newLastName, setNewLastName] = useState('');
	const [editUser, setEditUser] = useState(null);

	console.log(process.env.REACT_APP_API_URL);

	const [searchTerm, setSearchTerm] = useState('');

	const closeEditPasswordPopup = () => {
		setIsEditing(false);
		setEditingUsername('');
		setNewPassword('');
	};

	// Fetch users from the database when the component mounts
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/users`)
			.then((res) => res.json())
			.then((data) => setUsers(data))
			.catch((error) => console.error('Error fetching users:', error));
	}, []);

	// Function to filter users based on search term
	const filteredUsers = users.filter((user) => {
		return (
			user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
			user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			user.email.toLowerCase().includes(searchTerm.toLowerCase())
		);
	});

	const createUser = () => {
		fetch(`${process.env.REACT_APP_API_URL}/api/users/create-user`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username: newUsername,
				password: newPassword,
				email: newEmail,
				userRole: newUserRole,
				firstname: newFirstName,
				lastname: newLastName,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.status === 'success') {
					setMessage('Bruker opprettet'); // Set the message
					// setShowForm(false); // Hide the form
					// Reset the user form
					setNewUsername('');
					setNewPassword('');
					setNewUserRole('');
					setNewEmail('');
					setNewFirstName('');
					setNewLastName('');
					// Refetch the users
					fetch(`${process.env.REACT_APP_API_URL}/api/users`)
						.then((res) => res.json())
						.then((data) => setUsers(data))
						.catch((error) =>
							console.error('Error fetching users:', error)
						);
				} else {
					setMessage('Error creating user'); // Set the error message
				}
			})
			.catch((error) => {
				setMessage('Network error');
			});
	};

	const handleUpdateUser = (event) => {
		event.preventDefault();

		// Create the user update payload
		const updatedUserData = {
			username: editUser ? editUser.username : '', // Using the `editUser` state to get the username
			newPassword: newPassword,
			email: newEmail,
			userRole: newUserRole,
			firstname: newFirstName,
			lastname: newLastName,
		};

		// Remove any fields that haven't been changed to avoid unnecessary updates
		for (const key in updatedUserData) {
			if (updatedUserData[key] === '') {
				delete updatedUserData[key];
			}
		}

		// Call the API to update the user data
		fetch(`${process.env.REACT_APP_API_URL}/api/users/change-user`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(updatedUserData),
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				setMessage('Bruker ble vellykket oppdatert');
				setIsEditing(false); // Close the edit modal
				// Optionally, refresh the user list to reflect the changes
				fetch(`${process.env.REACT_APP_API_URL}/api/users`)
					.then((res) => res.json())
					.then((data) => setUsers(data))
					.catch((error) =>
						console.error('Error fetching users:', error)
					);
			})
			.catch((error) => {
				setMessage('Error updating user details. ' + error.message);
			});
	};

	const deleteUser = (username) => {
		fetch(`${process.env.REACT_APP_API_URL}/api/users/delete-user`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ username }),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.status === 'success') {
					// Remove the deleted user from the state
					setUsers(
						users.filter((user) => user.username !== username)
					);
				} else {
					console.log('Error deleting user');
				}
			})
			.catch((error) => {
				console.log('Network error:', error);
			});
	};

	const openEditUserPopup = (user) => {
		setIsEditing(true);
		setEditUser(user);
	};

	return (
		<div className='usermanagement'>
			<h2>User Management</h2>
			{message && <div className='CreateMessage'>{message}</div>}
			<div className='userSection'>
				<div className='createUserSection'>
					<button
						className='new-user-btn'
						onClick={() => setShowForm(true)}
					>
						Legg til ny bruker
					</button>
					{showForm && (
						<div className='newUserForm'>
							<input
								type='text'
								placeholder='Brukernavn'
								value={newUsername}
								onChange={(e) => setNewUsername(e.target.value)}
							/>
							<input
								type='password'
								placeholder='Passord'
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
							<input
								type='email'
								placeholder='Epost'
								value={newEmail}
								onChange={(e) => setNewEmail(e.target.value)}
							/>
							<input
								type='text'
								placeholder='Brukerrolle'
								value={newUserRole}
								onChange={(e) => setNewUserRole(e.target.value)}
							/>
							<input
								type='text'
								placeholder='Fornavn'
								value={newFirstName}
								onChange={(e) =>
									setNewFirstName(e.target.value)
								}
							/>
							<input
								type='text'
								placeholder='Etternavn'
								value={newLastName}
								onChange={(e) => setNewLastName(e.target.value)}
							/>
							<button className='addUser' onClick={createUser}>
								Opprett bruker
							</button>
							<button
								className='cancelUser'
								onClick={() => setShowForm(false)}
							>
								Avbryt
							</button>
						</div>
					)}
				</div>
				<div className='usersDisplay'>
					{/* New Search Bar */}
					<input
						type='text'
						placeholder='Søk etter brukere'
						value={searchTerm}
						className='searchUsers'
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<table>
						<thead>
							<tr>
								<th>Brukernavn</th>
								<th>Fornavn og Etternavn</th>
								<th>Epost</th>
								<th>Rolle</th>
								<th>Handlinger</th>
							</tr>
						</thead>
						<tbody>
							{filteredUsers.map((user) => (
								<tr key={user.id}>
									<td>{user.username}</td>
									<td>{`${user.first_name} ${user.last_name}`}</td>
									<td>{user.email}</td>
									<td>{user.userrole}</td>
									<td>
										<div className='changeUser'>
											<button
												className='btn-delete'
												onClick={() =>
													deleteUser(user.username)
												}
											>
												Slett
											</button>
											<button
												className='btn-change'
												onClick={() =>
													openEditUserPopup(user)
												}
											>
												Endre bruker
											</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<Modal isOpen={isEditing} onClose={closeEditPasswordPopup}>
					<h1>
						Endre bruker: <b>{editUser ? editUser.username : ''}</b>
					</h1>
					<form onSubmit={handleUpdateUser}>
						<input
							type='text'
							placeholder='Brukernavn'
							className='editUsernameField'
							value={
								editUser
									? editUser.username + ' (Kan ikke endres)'
									: ''
							}
							readOnly // Makes the input field read-only, so the username can't be changed
						/>
						<div>
							<input
								type='password'
								placeholder='Passord'
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
							<input
								type='email'
								placeholder={
									editUser ? editUser.email : 'Email'
								}
								value={newEmail}
								onChange={(e) => setNewEmail(e.target.value)}
							/>
						</div>
						<div>
							<input
								type='text'
								placeholder={
									editUser ? editUser.first_name : 'Fornavn'
								}
								value={newFirstName}
								onChange={(e) =>
									setNewFirstName(e.target.value)
								}
							/>
							<input
								type='text'
								placeholder={
									editUser ? editUser.last_name : 'Etternavn'
								}
								value={newLastName}
								onChange={(e) => setNewLastName(e.target.value)}
							/>
						</div>

						<input
							type='text'
							placeholder={
								editUser ? editUser.userrole : 'Bruker rolle'
							}
							value={newUserRole}
							onChange={(e) => setNewUserRole(e.target.value)}
						/>
						<button className='saveUser' type='submit'>
							Lagre bruker
						</button>
						<button
							className='closeModal'
							onClick={closeEditPasswordPopup}
						>
							Avbryt
						</button>
					</form>
				</Modal>
			</div>
		</div>
	);
};

export default UserManagement;
